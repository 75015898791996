<template>
  <form @submit.prevent="submitForm">
    <div class="field m-b-l">
      <heading size="4" :serif="false">
        {{ $t('pages.settings.personal.security') }}
      </heading>

      <div class="columns">
        <div v-if="currentUser.has_password" class="column">
          <form-field
            :validator="$v.form.current_password"
            :label="$t('forms.current_password')"
          >
            <input
              v-model="form.current_password"
              :placeholder="$t('forms.current_password')"
              class="input"
              type="password"
              name="current_password_field"
              autocomplete="off"
              @input="$v.form.current_password.$touch()"
            >
          </form-field>
        </div>

        <div class="column">
          <form-field
            :validator="$v.form.password"
            :label="$t('forms.new_password')"
          >
            <input
              v-model="form.password"
              :placeholder="$t('forms.new_password')"
              class="input"
              type="password"
              name="password_field"
              autocomplete="off"
              @input="$v.form.password.$touch()"
            >
          </form-field>
        </div>

        <div class="column">
          <form-field
            :validator="$v.form.password_confirmation"
            :label="$t('forms.new_password_confirmation')"
          >
            <input
              v-model="form.password_confirmation"
              :placeholder="$t('forms.new_password_confirmation')"
              class="input"
              type="password"
              name="password_confirmation"
              autocomplete="off"
              @input="$v.form.password_confirmation.$touch()"
            >
          </form-field>
        </div>
      </div>
    </div>

    <div class="field is-flex">
      <v-button
        :loading="form.isLoading"
        class="is-primary m-l-a"
        data-testid="ChangePassword"
      >
        {{ $t('forms.save') }}
      </v-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators'

import { isPasswordStrongEnough } from '@hypefactors/shared/js/utils/validation'

import Form from '@/services/forms/Form'

export default {
  validations () {
    return {
      form: this.form.rules()
    }
  },

  data () {
    return {
      form: new Form({
        current_password: {
          value: '',
          rules: {
            required: requiredIf(() => this.currentUser.has_password),
            'password.strength': isPasswordStrongEnough(6)
          }
        },

        password: {
          value: '',
          rules: {
            required,
            'password.strength': isPasswordStrongEnough(6)
          }
        },

        password_confirmation: {
          value: '',
          rules: {
            required,
            same_as: sameAs('password'),
            'password.strength': isPasswordStrongEnough(6)
          }
        }
      }),
      cancelToken: null
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    ...mapActions(['fetchUserRequest']),

    async submitForm () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.cancelToken = this.$api.cancelToken()

      try {
        await this.form.submit('post', 'user/update-password', {
          cancelToken: this.cancelToken.token
        })

        await this.fetchUserRequest()

        this.form.reset()
        this.$v.form.$reset()

        this.$notify.success(this.$t('success.user_password_updated'))
      } catch (error) {
        if (this.$api.isCancelToken(error)) {
          return
        }

        this.$displayRequestError(error)
      }
    }
  }
}
</script>
