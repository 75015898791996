<template>
  <form @submit.prevent="submitForm">
    <div class="field m-b-l">
      <heading size="4" :serif="false">
        {{ $t('pages.settings.personal.change_email') }}
      </heading>

      <div class="columns">
        <div class="column">
          <form-field :required="true" :label="$t('forms.current_email')">
            <input
              class="input"
              type="email"
              name="email_field"
              autocomplete="off"
              disabled="disabled"
              :value="currentUser.email"
            >
          </form-field>
        </div>

        <div class="column">
          <form-field
            :validator="$v.form.email"
            :label="$t('forms.new_email')"
          >
            <input
              v-model="form.email"
              :placeholder="$t('forms.new_email')"
              class="input"
              type="email"
              name="email_field"
              autocomplete="off"
              @input="$v.form.email.$touch()"
            >
          </form-field>
        </div>

        <div class="column">
          <form-field
            :validator="$v.form.current_password"
            :label="$t('forms.current_password')"
          >
            <input
              v-model="form.current_password"
              :placeholder="$t('forms.current_password')"
              class="input"
              type="password"
              name="current_password_field"
              autocomplete="off"
              @input="$v.form.current_password.$touch()"
            >
          </form-field>
        </div>
      </div>
    </div>

    <div class="field is-flex">
      <v-button
        :loading="form.isLoading"
        class="is-primary m-l-a"
        data-testid="ChangeEmail"
      >
        {{ $t('forms.save') }}
      </v-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'

import Form from '@/services/forms/Form'

const form = new Form({
  email: {
    value: '',
    rules: {
      required,
      email
    }
  },

  current_password: {
    value: '',
    rules: {
      required
    }
  }
})

export default {
  validations: {
    form: form.rules()
  },

  data () {
    return {
      form: form,
      cancelToken: null
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    ...mapActions(['fetchUserRequest']),

    async submitForm () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.cancelToken = this.$api.cancelToken()

      try {
        await this.form.submit('post', 'user/update-email', {
          cancelToken: this.cancelToken.token
        })

        await this.fetchUserRequest()

        this.form.reset()
        this.$v.form.$reset()

        this.$notify.success(this.$t('success.user_email_updated'))
      } catch (error) {
        if (this.$api.isCancelToken(error)) {
          return
        }

        this.$displayRequestError(error)
      }
    }
  }
}
</script>
