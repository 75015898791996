<template>
  <PageSection
    :page-tooltip="$t('pages.settings.personal.preferences.sidebar_description')"
    :page-title="$t('pages.settings.personal.personal_account_settings')"
  >
    <AccountSettingsForm />

    <hr class="m-v-l">

    <ChangeEmailForm />

    <hr class="m-v-l">

    <ChangePasswordForm />
  </PageSection>
</template>

<script>
import { mapGetters } from 'vuex'

import PageSection from '@/components/core/PageSection.vue'
import AccountSettingsForm from '@/pages/settings/components/personal/AccountSettingsForm.vue'
import ChangeEmailForm from '@/pages/settings/components/personal/ChangeEmailForm.vue'
import ChangePasswordForm from '@/pages/settings/components/personal/ChangePasswordForm.vue'

export default {
  components: {
    PageSection,
    AccountSettingsForm,
    ChangeEmailForm,
    ChangePasswordForm
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  beforeRouteLeave (to, from, next) {
    if (!this.currentUser.has_password && to.name !== 'logout') {
      this.$confirm(this.$t('pages.settings.personal.confirm_complete_account'), {
        showCancelButton: false
      }).catch(() => {})

      return next(false)
    }

    next()
  }
}
</script>
